@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes zoomIn110 {
	0% {
		width: 100%;
		height: 100%;
		margin-top: 0%;
		margin-left: 0%;
	}
	100% {
		width: 110%;
		height: 110%;
		margin-top: -5%;
		margin-left: -5%;
	}
}
@keyframes zoomOut110 {
	0% {
		width: 110%;
		height: 110%;
		margin-top: -5%;
		margin-left: -5%;
	}
	100% {
		width: 100%;
		height: 100%;
		margin-top: 0%;
		margin-left: 0%;
	}
}
