html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.CenteredX {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}

.CenteredY {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	vertical-align: top;
}

.CenteredXY {
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	vertical-align: top;
}

.h100 {
	height: 100%;
}

.w100 {
	width: 100%;
}

.mh100 {
	min-height: 100%;
}

.MousePointer {
	cursor: pointer;
}

p {
	font-size: 15px;
	font-weight: 300;
	line-height: 1.6;
	margin-bottom: 1em;
}

.Alert {
	margin-top: 0.3em;
}

.NoVisible {
	display: none !important;
}

.alignSelfCenter {
	align-self: center;
}

.textAlignCenter {
	text-align: center;
}

.textAlignRight {
	text-align: right;
}

.no-border {
	border: none !important;
}

/** style defined by art **/
.colorText {
	color: #191919;
}

.colorHighText {
	color: #bc2660;
}

.colorContainer {
	background-color: #c9c7c7;
}

.greenGradient {
	background: rgba(168, 177, 43, 1);
	background: linear-gradient(90deg, rgba(168, 177, 43, 1) 0%, rgba(23, 152, 0, 1) 100%);
	color: #ffffff !important;
}

.redGradient {
	background: rgb(228, 135, 43);
	background: linear-gradient(90deg, rgba(228, 135, 43, 1) 0%, rgba(200, 23, 128, 1) 100%);
	color: #ffffff !important;
}

.greyGradient {
	background: rgb(212, 212, 212);
	background: linear-gradient(90deg, rgba(212, 212, 212, 1) 0%, rgba(179, 179, 179, 1) 100%);
	color: #191919 !important;
}
